/* Cards */
.card {
  border: 0;
  @include border-radius(2px);

  .card-body {
    padding: $card-padding-y $card-padding-x;

    + .card-body {
      padding-top: 0;
    }
  }
}