/* Utilities */
.grid-margin {
  margin-bottom: $grid-gutter-width;
}

.grid-margin-sm-0 {
  @media (min-width: 576px) {
    margin-bottom: 0;
  }
}

.grid-margin-md-0 {
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
}

.grid-margin-lg-0 {
  @media (min-width: 992px) {
    margin-bottom: 0;
  }
}

.grid-margin-xl-0 {
  @media (min-width: 1200px) {
    margin-bottom: 0;
  }
}

.img-lg {
  width: 92px;
  height: 92px;
}

.img-md {
  width: 60px;
  height: 60px;
}

.img-sm {
  width: 43px;
  height: 43px;
}

.img-xs {
  width: 37px;
  height: 37px;
}

.img-ss {
  width: 26px;
  height: 26px;
}

.stretch-card {
  @include display-flex;
  @include align-items(stretch);
  @include justify-content(stretch);

  > .card {
    width: 100%;
    min-width: 100%;
  }
}

.border-top {
  border-top: $border-width solid $border-color;
}

.border-bottom {
  border-bottom: $border-width solid $border-color;
}

.border-right {
  border-right: $border-width solid $border-color;
}

.border-left {
  border-left: $border-width solid $border-color;
}

.border-right-sm {
  @media (min-width: 576px) {
    border-right: $border-width solid $border-color;
  }
}

.border-right-md {
  @media (min-width: 768px) {
    border-right: $border-width solid $border-color;
  }
}

.border-right-lg {
  @media (min-width: 992px) {
    border-right: $border-width solid $border-color;
  }
}

.border-left-sm {
  @media (min-width: 576px) {
    border-left: $border-width solid $border-color;
  }
}

.border-left-md {
  @media (min-width: 768px) {
    border-left: $border-width solid $border-color;
  }
}

.border-bottom-none {
  border-bottom: none;
}

.border-left-lg {
  @media (min-width: 992px) {
    border-left: $border-width solid $border-color;
  }
}

.text-gray {
  color: #8c8c8c;
}

.text-black {
  color: $black;
}

.flex-grow {
  flex-grow: 1;
}

.bg-transparent {
  background: transparent;
}

.item-relative {
  position: relative;
}

.profile-image {
  position: relative;

  img {
    width: 40px;
    height: 40px;
    @include border-radius(100%);
  }

  .online-status {
    position: absolute;
    bottom: 3px;
    right: -3px;
    height: 10px;
    width: 10px;
    border: 1px solid $white;
    display: block;
    @include border-radius(100%);

    &.online {
      background: theme-color(success);
    }
  }
}