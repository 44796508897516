/* Demo Styles */
// Add spacing to Boostrap components for demo purpose
.template-demo {
  > .btn {
    margin-top: 20px;
    margin-right: 15px;
  }

  > .btn-toolbar {
    margin-top: 20px;
    margin-right: 15px;
  }

  > .btn-group {
    margin-top: 20px;
    margin-right: 15px;

    .btn {
      margin: 0 !important;
    }
  }

  > h2,
   > h3,
   > h4,
   > h5,
   > h6,
  > h1 {
    border-top: 1px solid $border-color;
    padding: 0.5rem 0 0;
  }
}