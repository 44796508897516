.performance-cards {
  .wrapper {
    p {
      margin-bottom: 0;
      line-height: 1;

      &:first-child {
        font-family: $TYPE-1;
        font-weight: 600;
        font-size: 30px;
      }

      &:last-child {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 20px;
        color: color(gray-light);
      }
    }

    &.icon-circle {
      width: 73px;
      height: 73px;
      @include border-radius(50px);
      @include display-flex;
      @include align-items(center);
      @include justify-content(center);
      padding: 0;
      margin-right: 15px;

      i {
        font-size: 1.6rem;
        color: $white;
      }
    }
  }
}

.social-section {
  div {
    .social-card {
      @include display-flex;
      @include align-items(center);
      @include justify-content(center);
      color: $white;

      .social-icon {
        @include display-flex;
        @include align-items(center);
        @include justify-content(center);
        @include border-radius(50px);
        background: rgba($white, 0.2);
        width: 86px;
        height: 82px;

        i {
          font-size: 2.0625rem;
        }
      }

      .content {
        @include display-flex;
        @include justify-content(center);
        @include flex-direction(column);
        height: 125px;

        p {
          text-align: left;
          margin-bottom: 0;
          margin-left: 1.2rem;
          font-weight: 500;

          &:first-child {
            font-size: 25px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.dashboard-curved-chart {
  padding-left: 0 !important;
  padding-right: 0 !important;

  .nav-wrapper {
    .nav {
      border: 1px solid $border-color;
      @include border-radius(2px);

      .nav-item {
        .nav-link {
          @include border-radius(0);
          font-size: 0.775rem;
          color: $text-muted;

          &.active {
            background: theme-color(secondary);
            color: $white;
          }
        }
      }
    }
  }

  #chartLegend {
    overflow-x: auto;

    table {
      tr {
        td {
          &.legendColorBox {
            width: 20px;
            height: 20px;
            margin-right: 15px;
            padding: 0 15px 0 22px;
            @include border-radius(100%);
            overflow: hidden;

            &:first-child {
              padding-left: 0;
            }

            div {
              border-width: 0 !important;

              div {
                @include border-radius(100%);
                border-width: 13px !important;
              }
            }
          }

          &.legendLabel {
            padding: 0 25px 0 0;
            border-right: 1px solid color(gray-lightest);
            font-weight: 600;

            &:last-child {
              border-right: none;
            }
          }
        }
      }
    }
  }

  .float-chart {
    height: 250px;
    width: calc(100% + 21px);
    margin-left: -11px;
    margin-right: -11px;
    margin-bottom: -15px;
  }
}